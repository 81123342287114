import React from 'react';
import './CreatorPage.css'; // 新しいCSSファイルをインポート
import './StylishTextPage.css'; // CSSファイルのインポート

const CreatorPage = () => {
  return (
    <div className="creator-page">
      <h1>クリエイター紹介</h1>
      <div className="creator-container">
        <div className="creator">
          <div className="creator-info">
            <h2 style={{ color: 'white' }}>Tony</h2>
            <div className="paragraph">曲制作、MIX、ドラムを叩いている人であり、本サイトの管理人です。</div>
            <div className="paragraph">自分の全てを賭けて、本サイトを有名にできたらと思っているので、全力で運営していきます。</div>
            <div className="paragraph">作る楽曲はチル系やロック、EDM、ジャンルは幅広く制作しています。</div>
            <div className="paragraph">作った曲のドラムは大半自分で叩いてます。用途に合ったBGMが欲しい方は個人的に依頼も受けております。</div>
            <p></p>
            <a href="https://lit.link/tonymusicdrum">詳細プロフへ</a>
          </div>
          <div className="creator-image">
            <img src="tony.png" alt="creator 1" /> {/* クリエイター写真 */}
          </div>
        </div>
        <div className="creator">
          <div className="creator-info">
            <h2 style={{ color: 'white' }}>74Zarashi</h2>
            <div className="paragraph">曲制作、イラスト制作などしており、</div>
            <div className="paragraph">本サイトのイメージイラストの製作者です。</div>
            <div className="paragraph">Twitchで配信もたまにしているので、観に来てもらえると嬉しいです。</div>
            <div className="paragraph">EDMを中心に、色々な楽曲を制作しています。</div>
            <div className="paragraph">イラスト制作依頼、BGM制作依頼を受け付けております。</div>
            <p></p>
            <a href="https://lit.link/74zarashi">詳細プロフへ</a>
          </div>
          <div className="creator-image">
            <img src="74zarashi.png" alt="creator 2"  /> {/* クリエイター写真 */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatorPage;
