import React, { useEffect } from 'react';
import RotatingVideos from './RotatingVideos';
import './StylishTextPage.css'; // CSSファイルのインポート



function AboutPage() {

  useEffect(() => {
    // Twitterのウィジェットスクリプトをロード
    const script = document.createElement('script');
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className="first-stylish">
      <h2>About Us</h2>
      <div className="stylish-container">
        <div className="paragraph">このサイトでは、複数名のクリエイターのFreeBGMを公開しております。</div>
        <div className="paragraph">動画編集者、配信者、TRPGやゲーム製作者等に使用して頂けるようなものを更新していきます。</div>
        <div className="paragraph">こちらのサイトから各クリエイターに興味を持って頂けたり、楽曲を気に入って聴いてもらえたり、</div>
        <div className="paragraph">参加して頂いてるクリエイターの知名度をもっと広めていきたいです！</div>
      </div>
      
      <h2>NEWS</h2>      
      <div className="news-section">
        <ul className="news-list">
          <li>2024/10/27 Youtube更新されました！プレイリストも作成されました！</li>
          <li>2024/10/27 BGMに6曲追加!!新曲タグで検索できます!</li>
          <li>2024/10/27 ジングル1曲追加！newタグで検索できます！</li>
          <li>2024/9/1 サイト公開!!</li>
          {/* 2024/9/30 新曲3曲追加!!Youtube更新! */}
          {/* 2024/9/27 再生機能修正（曲再生中に次の曲を連続で再生可能になりました） */}
          {/* 2024/9/1 YouTube投稿しました！ */}
          {/* ３つまで残し、古いものはこのかっこで閉じる */}
        </ul>
      </div>

      <div className="pointindex">Youtubeはこちら↓</div>
      <RotatingVideos />
      <div className="pointindex">最新の情報はXアカウントチェック!</div>
      <div>
        {/* Twitterフォローボタンの埋め込み */}
        <a
          href="https://twitter.com/fmc_239?ref_src=twsrc%5Etfw"
          className="twitter-follow-button"
          data-show-count="false"
        >
          Follow @fmc_239
        </a>
      </div>

      <h2>CONTACT</h2>
      <div className="stylish-container">
        <div className="paragraph">サイトについて、何かご要望等ございましたら、</div>
        <div className="paragraph">以下アドレスにご連絡ください。</div>
        <div className="paragraph">fmc239officialsup@gmail.com</div>
      </div>
    </div>
  );
}

export default AboutPage;

