import React, { useState, useEffect, useRef } from 'react';
import { songs as bgmSongs } from './data';
import { songs as seSongs } from './datase'; // SE・ジングル用のデータ
import './SoundsPage.css';
import { FaPlay, FaPause, FaDownload, FaVolumeUp } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';

const SoundsPage = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPlayingId, setCurrentPlayingId] = useState(null); // 現在再生中の曲のID
  const location = useLocation();
  const navigate = useNavigate();

  const audioRef = useRef(new Audio()); // 1つのaudioRefを全体で共有

  const songsPerPage = 5;

  const isBgm = location.pathname.includes('/bgm');
  const songs = isBgm ? bgmSongs : seSongs;

  useEffect(() => {
    const initialSearchKeyword = new URLSearchParams(location.search).get('search') || '';
    setSearchKeyword(initialSearchKeyword);
    handleSearch(initialSearchKeyword); // 初期検索を実行

    const tags = songs.reduce((acc, song) => {
      song.tags.forEach(tag => {
        if (!acc.includes(tag)) {
          acc.push(tag);
        }
      });
      return acc;
    }, []);
    setAllTags(tags);
  }, [isBgm, location.search]);

  const handleToggle = (isBgm) => {
    const newPath = isBgm ? '/sounds/bgm' : '/sounds/se';
    navigate(newPath); // 切り替え時にURLを変更
    setSearchResults([]); // 検索結果をクリア
    setSearchKeyword(''); // 検索キーワードをクリア
  };

  const handleSearch = (keyword) => {
    if (keyword === '') {
      setSearchResults(songs);
    } else {
      const tags = keyword.split(/[, ]+/);
      let results = songs.filter(song => {
        const titleMatch = song.title.toLowerCase().includes(keyword.toLowerCase());
        const tagMatch = tags.some(tag => song.tags.includes(tag.trim().toLowerCase()));
        return titleMatch || tagMatch;
      });

      results = results.filter((song, index) => results.findIndex(s => s.title === song.title) === index);
      setSearchResults(results);
    }
    setCurrentPage(1);
  };

  const handleSearchInputChange = (e) => {
    setSearchKeyword(e.target.value);
  };

  const handleSearchButtonClick = () => {
    handleSearch(searchKeyword);
  };

  const handleTagClick = (tag) => {
    setSearchKeyword(tag);
    handleSearch(tag);
  };

  const handleDownload = (song) => {
    // Google Analytics イベント送信: ダウンロード
    const decodedTitle = decodeURIComponent(song.title); // 日本語の曲名をデコード
  
    // Google Analytics イベント送信: ダウンロード
    if (window.gtag) {
      window.gtag('event', 'download', {
        event_category: 'Download',
        event_label: decodedTitle, // デコードされた曲名をラベルとして送信
        value: 1
      });
    }

    const link = document.createElement('a');
    link.download = song.title + '.mp3';
    link.href = song.downloadUrl;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // CustomAudioPlayer コンポーネント
  const CustomAudioPlayer = ({ src, audioRef, currentPlayingId, setCurrentPlayingId, songId, songTitle }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [volume, setVolume] = useState(1);
    const [showVolumeSlider, setShowVolumeSlider] = useState(false);

    useEffect(() => {
      const updateProgress = () => {
        setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100);
      };

      if (audioRef.current) {
        audioRef.current.addEventListener('timeupdate', updateProgress);
      }

      return () => {
        if (audioRef.current) {
          audioRef.current.removeEventListener('timeupdate', updateProgress);
        }
      };
    }, [audioRef]);

    useEffect(() => {
      // 別の曲が再生された場合、自分は停止
      if (isPlaying && currentPlayingId !== songId) {
        setIsPlaying(false);
      }
    }, [currentPlayingId, songId, isPlaying]);

    const togglePlay = () => {
      if (currentPlayingId !== songId) {
        if (audioRef.current.src !== src) {
          audioRef.current.src = src; // 音源の切り替え
        }
        setIsPlaying(true);
        audioRef.current.play();
        setCurrentPlayingId(songId); // 現在再生中の曲のIDを更新

        // 日本語曲名をデコードしてGoogle Analyticsに送信
        const decodedTitle = decodeURIComponent(songTitle);

        // Google Analytics イベント送信: 再生
        if (window.gtag) {
          window.gtag('event', 'play', {
            event_category: 'Audio',
            event_label: decodedTitle, // デコードされた曲名をラベルとして送信
            value: 1
          });
        }
      } else {
        if (isPlaying) {
          audioRef.current.pause();
        } else {
          audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
      }
    };

    const handleProgressChange = (event) => {
      const progressValue = event.target.value;
      setProgress(progressValue);
      audioRef.current.currentTime = (audioRef.current.duration * progressValue) / 100;
    };

    const handleVolumeChange = (event) => {
      const volumeValue = event.target.value;
      setVolume(volumeValue);
      audioRef.current.volume = volumeValue;
    };

    const toggleVolumeSlider = () => {
      setShowVolumeSlider(!showVolumeSlider);
    };

    return (
      <div className="custom-audio-player">
        <button onClick={togglePlay} className="button">
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>

        <div className="volume-control">
          <button onClick={toggleVolumeSlider} className="volume-button">
            <FaVolumeUp />
          </button>
          {showVolumeSlider && (
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
              className="volume-slider"
            />
          )}
        </div>

        <input
          type="range"
          min="0"
          max="100"
          value={progress}
          onChange={handleProgressChange}
          className="progress-bar"
        />

        <button onClick={() => handleDownload({ title: "sample", downloadUrl: src })} className="download-button">
          <FaDownload />
        </button>
      </div>
    );
  }; // CustomAudioPlayer コンポーネント終了

  const indexOfLastSong = currentPage * songsPerPage;
  const indexOfFirstSong = indexOfLastSong - songsPerPage;
  const currentSongs = searchResults.slice(indexOfFirstSong, indexOfLastSong);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div className="toggle-buttons">
        <button onClick={() => handleToggle(true)} className={`button ${isBgm ? 'active' : ''}`}>BGM</button>
        <button onClick={() => handleToggle(false)} className={`button ${!isBgm ? 'active' : ''}`}>SE・ジングル</button>
      </div>

      <div className="search-bar">
        <input type="text" value={searchKeyword} onChange={handleSearchInputChange} />
        <button onClick={handleSearchButtonClick} className="button">Search</button>
      </div>

      <div className="tags-container">
        {allTags.map((tag, index) => (
          <button key={index} className="tag" onClick={() => handleTagClick(tag)}>
            {tag}
          </button>
        ))}
      </div>

      <div className="song-container">
        {currentSongs.map((song) => (
          <div key={song.title} className="song">
            <h2 style={{ color: 'white' }}>{song.title}</h2>

            {isBgm && song.youtubeEmbedUrl && song.youtubeEmbedUrl !== 'SE' && (
              <div className="video-wrapper">
                <iframe
                  width="400"
                  height="213"
                  src={song.youtubeEmbedUrl.replace("watch?v=", "embed/")}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}

            {isBgm && !song.youtubeEmbedUrl && (
              <div className="video-wrapper">
                <img
                  src={`${process.env.PUBLIC_URL}/239waiting.png`}
                  alt="準備中"
                  width="400"
                  height="213"
                />
              </div>
            )}

            <p style={{ whiteSpace: 'pre-wrap' }}>{song.info}</p>

            <div className="buttons" style={{ display: 'flex', justifyContent: 'center' }}>
              <CustomAudioPlayer
                src={song.downloadUrl}
                audioRef={audioRef}
                currentPlayingId={currentPlayingId}
                setCurrentPlayingId={setCurrentPlayingId}
                songId={song.title} // 曲を識別するためのIDとして曲のタイトルを使用
                songTitle={song.title} // Google Analytics用の曲名
              />
            </div>
            <p>Tags: {song.tags.join(', ')}</p>
          </div>
        ))}
      </div>

      <Pagination
        songsPerPage={songsPerPage}
        totalSongs={searchResults.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
}; // SoundsPage コンポーネント終了

// Pagination コンポーネント
const Pagination = ({ songsPerPage, totalSongs, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalSongs / songsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (number) => {
    paginate(number);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map(number => (
          <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
            <button onClick={() => handlePageChange(number)} className="page-link">
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

// formatInfoText 関数
const formatInfoText = (text) => {
  if (!text) return '';
  return text.replace(/\*\*(.*?)\*\*/g, (match, p1) => p1);
};

export default SoundsPage;
