import React from 'react';
import './StylishTextPage.css'; // CSSファイルのインポート

const TermsOfServicePage = () => {
  return (
    <div classname="first-stylish">
     <h2>利用規約</h2>  
      <div className="stylish-container">
        <p>以下がFreeMusicCreators(239)の利用規約となります。本規約に同意いただける場合のみ、本サービスをご利用ください。</p>
     
      <div className="stylish-content">
        <ol>
          <li>動画制作、ゲーム制作、ライブ配信、告知PV、TRPG等での使用を想定しており、商用・非商用問わずご使用頂けます。</li>
          <li>クレジットについて、ライブ配信以外の制作する作品には「FMC」または「239」と表記お願いします。 表記場所に指定はありません。表記が難しいといった場合には表記なしでも構いません。</li>
          <li>再配布、自作発言、転売等は禁止です。</li>
          <li>公序良俗に反する行為や政治的活動での使用は禁止です。</li>
          <li>著作権について、本サービスで提供されるすべてのコンテンツの著作権は各製作者（クリエイター）に帰属します。</li>
          <li>規約の変更について、本利用規約は、予告なく変更されることがあります。変更後の利用規約は、本サービスのウェブサイトに掲載された時点で効力を生じますので、定期的にご確認ください。</li>

        </ol>
      </div> 
      </div>
     </div>
  );
}

export default TermsOfServicePage;

